import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReleaseInvoice(ctx, params) {
      return useJwt.getReleaseInvoices(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getReleaseInvoiceAnalytics(params).then(response => response);
    },
    previewReleaseInvoice(ctx, releaseInvoiceId) {
      return useJwt.previewReleaseInvoice(releaseInvoiceId).then(response => response);
    },
    deleteReleaseInvoice(ctx, releaseInvoiceId) {
      return useJwt.cancelReleaseInvoice(releaseInvoiceId).then(response => response);
    },

    updatePaymentStatusReleaseInvoice(ctx, params) {
      return useJwt.updatePaymentStatusReleaseInvoice(params).then(response => response);
    },

    cancelPaymentStatusReleaseInvoice(ctx, params) {
      return useJwt.cancelPaymentStatusReleaseInvoice(params).then(response => response);
    },

    exportReleaseInvoices(ctx, params) {
      return useJwt.exportReleaseInvoices(params).then(response => response);
    },
  },
};
